import React from 'react'
import {Form, useDynamicForm} from "kaiju.core";
import {Button} from "antd";
import i18n from "i18next";

const Settings: React.FC<{ mediaId?: string }> = ({mediaId}) => {
    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        saveDiff: false,
        loadRequest: {
            method: "ContentTypeBuilder.medialibrary.settings",
            params: {
                media: mediaId
            }
        },
        loadExtraParams: {
            media: mediaId
        },
        saveRequest: {
            method: "ContentTypeBuilder.medialibrary.settings.update",
            params: {
                media: mediaId
            }
        },
        successCallback: (props) => {
            console.log("SUCCES SAVE")
        }
    })
    return (
        <div style={{maxWidth: 500}}>
            <Form store={store.formStore}>
                <Button onClick={()=>{store.submit()}} type={"primary"}>{i18n.t("common.save")}</Button>
            </Form>
        </div>
    )

}

export {Settings}
