import React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {Tabs, Card, Typography} from "antd";
import i18n from "i18next";
import {HeaderComponent} from "src/components/Header";
import {Attributes} from "./Attributes";
import {Permissions} from "./Permissions";
import {Columns} from "./Columns";
import {useList} from "src/components/List";
import {Settings} from "./Settings"


const CTBCollectionPage: React.FC = () => {
    const {breadcrumbs, builderStore} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const {collectionId, appId} = useParams<{ collectionId: string, appId: string }>()
    const current = builderStore.data.contents.filter((el: any) => (el._id === (collectionId ? collectionId : undefined)))[0]
    const [listStore] = useList(collectionId, {
        request: {
            method: "ContentTypeBuilder.collection.attribute.list",
            params: {
                collection: collectionId
            }
        },
        requestSort: {
            method: "ContentTypeBuilder.collection.attribute.sort",
            params: {
                collection: collectionId
            }
        }
    })

    const items = [
        {
            key: 'attributes',
            label: i18n.t("attribute.label"),
            children: <Attributes appId={appId} collectionId={collectionId} listStore={listStore} key={'attr-' + collectionId}/>,
        },
        {
            key: 'settings',
            label: i18n.t("common.settings"),
            children: <Settings collectionId={collectionId} key={'settings-' + collectionId}/>,
        },
        {
            key: 'columns',
            label: i18n.t("common.columns"),
            children: <Columns collectionId={collectionId}/>,
        },
        // {
        //     key: 'sort',
        //     label: i18n.t("common.sort"),
        //     children: <Sort contentId={contentId}/>,
        // },
        {
            key: 'permissions',
            label: i18n.t("common.permissions"),
            children: <Permissions collectionId={collectionId}/>,
        },
    ];

    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("content-builder.label"),
            },
            {
                title: i18n.t("collection.label"),
            },
            {
                title: current ? current.label : "[...]",
            },
        ]}/>

        <Typography.Title level={1} copyable={{text: current?.id, tooltips: current?.id}} style={{marginTop: 0}}>
            {/*ID: {current ? current.id : "[...]"}*/}
            {current ? current.label : "[...]"}
        </Typography.Title>

        <p style={{marginTop: "1rem", marginBottom: "2rem", color: "rgb(140 140 140)"}}>{i18n.t("collection.moto")}</p>
        <Card>
            <Tabs defaultActiveKey="1" items={items}/>
        </Card>
    </>
}

export {CTBCollectionPage}
