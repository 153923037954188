import React from "react";
import {Card, Flex, Tag} from "antd";
import type {StoreInterface} from '../store'

interface AssetProps {
    id: string,
    file_type: string,
    status: string,
    name: string,
    link: string,
    extension: string,
    size: number,
    meta: { [p: string]: any }
}

export const AudioView: React.FC<{ asset: AssetProps, store: StoreInterface, isSimple?: boolean }> = ({asset, isSimple, store}) => {
    return (
        <Card styles={{body: {padding: 0}}} style={{width: 310, cursor: "pointer"}}>
            <Flex vertical>
                <div className={"base-comp audio-comp"} style={{height: 120}}>
                    <audio controls autoPlay={false}>
                        <source src={asset.link} type="audio/mp3" />
                    </audio>
                </div>
                <div style={{padding: 15, borderTop: "1px solid #80808036"}}>
                    <div>
                        <div style={{paddingBottom: 5}}>{asset.name}</div>
                        <div style={{fontSize: 12}}>
                            <Tag style={{fontSize: 12}}>{asset.file_type.toUpperCase()}</Tag>
                            <Tag style={{fontSize: 12}} color={"geekblue"}>{asset.extension.toUpperCase()}</Tag>
                        </div>
                    </div>
                </div>
            </Flex>

        </Card>
)
}