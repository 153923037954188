import React from 'react'
import i18n from "i18next";
import {useOutletContext, useParams, Outlet, generatePath, useLoaderData} from "react-router-dom";
import {ROUTE} from "src/routing";

const CContentRootPage: React.FC = () => {
    const {breadcrumbs, builderStore} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const params = useParams<{ collectionId: string, itemId: string }>()
    const current = builderStore.data.collection.filter((el: any) => (el._id === (params.collectionId ? params.collectionId : undefined)))[0]
    const contentData = useLoaderData()
    const fullBreadcrumbs = [
        ...breadcrumbs,
        {
            title: <a href={generatePath(ROUTE.ContentRoot, params)}>{i18n.t("collection.label")}</a>,
        },
        {
            title: current ? (<a href={generatePath(ROUTE.ContentList, params)}>{current.label}</a>) : "[...]",
        },
    ]
    return (
        <Outlet context={{builderStore, breadcrumbs: fullBreadcrumbs, contentData}}/>
    )
}

export {CContentRootPage}
