import React from 'react'
import {Button, Drawer, Flex, Select, App, Divider} from "antd";
import i18n from "i18next";
import {Request} from "kaiju.core";
import {ConditionFilters} from "src/components/ConditionFilters";
import type {StoreInterface} from "src/components/ConditionFilters";
import store from "src/pages/Auth/SignIn/store";

const FiltersComponent: React.FC<{
    setFilter: any,
    params: any,
    filterStore: StoreInterface,
    openFilters: any,
    setOpenFilters: any
}> = ({
          setFilter,
          params,
          filterStore,
          openFilters,
          setOpenFilters
      }) => {
    const [allFilters, setAllFilters] = React.useState<any[]>([])
    const {notification} = App.useApp()
    const loadFilters = () => {
        Request("CollectionGui.filter.all", params).then((response) => {
            setAllFilters(response.result)
        })
    }
    React.useEffect(() => {
        loadFilters()
    }, [])

    return (<Drawer
        title={i18n.t("filters.label")}
        width={600} onClose={() => {
        setOpenFilters(false)

    }}
        styles={{body: {background: "#f5f5f5"}}}
        open={openFilters}
        extra={
            <Flex wrap gap="small">
                <Button type={"primary"} onClick={() => {
                    setFilter(filterStore.activeFilters())
                }}>
                    {i18n.t("common.apply")}
                </Button>
                <Button onClick={() => {
                    filterStore.clear()
                    setFilter([])
                }}>
                    {i18n.t("common.clear")}
                </Button>

            </Flex>
        }
    >
        <ConditionFilters store={filterStore}/>
        <Divider/>
        <Select style={{width: "100%", marginBottom: 10}}
                options={allFilters.map((val: any) => ({value: val.id, label: val.label}))}
                value={[]}
                placeholder={i18n.t("filter.select-filter")}
                defaultValue={{}}
                onChange={(value: any) => {
                    const _filter = allFilters.find((el: any) => (el.id === value))
                    filterStore.addFilter({
                        ..._filter,
                        key: `filter-${new Date().valueOf()}`
                    })
                }}
        />
    </Drawer>)
}

export {FiltersComponent}
