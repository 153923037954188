import React from 'react'
import {Request} from "kaiju.core";
import {App} from "antd"

export const useStore = ({appId, singleId}: any) => {
    const [data, setData] = React.useState<any[]>([])
    const [roles, setRoles] = React.useState<any[]>([])
    const [columns, setColumns] = React.useState<any[]>([])
    const permissions: { [p: string]: any } = {}
    const [activeRoles, setActiveRoles] = React.useState<{ [p: string]: string }>({})
    const {notification} = App.useApp()

    const handleSetData = (d: any[]) => {
        const rActive: { [p: string]: string } = {}
        d.forEach((el: any) => {
            rActive[el.role_id] = el.role
        })
        setActiveRoles({...activeRoles, ...rActive})
        setData(d)
    }

    const loadData = async (singleId: any) => {
        const {result} = await Request("ContentTypeBuilder.single.permission.list", {application: appId, single: singleId})
        setColumns(result?.columns || [])
        handleSetData(result?.data || [])
    }

    const loadRoles = async () => {
        const resp = await Request("Role.list", {})
        setRoles(resp.result)
    }

    const setPermission = (role: string, permission: string, value: boolean) => {
        if (!Object.keys(permissions).includes(role)) {
            permissions[role] = {}
        }
        permissions[role][permission] = value
    }

    const loadAll = async (collectionId: string) => {
        await loadData(collectionId)
        await loadRoles()
    }

    const addRole = (roleId: string) => {
        const newRow: any[] = []

        roles.forEach((el: any) => {
            if (el.value === roleId) {
                newRow.push({
                    role: el.label,
                    role_id: el.value,
                })
            }
        })
        if (!Object.keys(permissions).includes(roleId)) {
            permissions[roleId] = {}
        }

        handleSetData([...data, ...newRow])
    }

    const getRoles = () => {
        return roles.filter((el: any) => (!Object.keys(activeRoles).includes(el.value)))
    }

    const save = () => {
        Request("ContentTypeBuilder.single.permission.set", {application: appId, single: singleId, permissions: permissions}).then(() => {
            notification.success({
                duration: 2,
                message: "permission.saved",
            });
        })

    }

    React.useEffect(() => {
        loadAll(singleId).then()
    }, [singleId])

    return {
        data,
        getRoles,
        columns,
        roles,
        addRole,
        setPermission,
        save,
    }
}