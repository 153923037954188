import React from 'react'
import {ListComponent} from "src/components/List";
import i18n from "i18next";
import {Col, Row, Typography, Tag, Input, Divider, Flex, Button, Popconfirm} from "antd";
import {DeleteOutlined, QuestionCircleOutlined, SearchOutlined, PlusOutlined} from "@ant-design/icons"
import {Request} from "kaiju.core";
import {CreateAttribute} from './CreateAttribute'
import {AttributeEdit} from './AttributeEdit'
import './style.scss'


interface Props {
    appId?: string,
    collectionId?: string,
    listStore: any
}

const Attributes: React.FC<Props> = ({
                                         appId,
                                         collectionId,
                                         listStore
                                     }) => {
    const [open, setOpen] = React.useState(false);
    const [attrId, setAttrId] = React.useState<string | undefined>()
    const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
    const [deleteID, setDeleteId] = React.useState<string | undefined>(undefined);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        listStore.loadData()
    };
    const onCloseView = () => {
        setAttrId(undefined)
    }
    const deleteAttribute = (collectionId: string, id: string) => {
        setConfirmLoading(true)
        Request("ContentTypeBuilder.collection.attribute.delete", {
            collection: collectionId,
            id: id
        }).then((response: any) => {
            setDeleteId(undefined)
            setConfirmLoading(false)
            listStore.loadData()
        })
    }

    return (
        <>
            <Flex justify={"space-between"}>
                <Input prefix={<SearchOutlined/>} style={{maxWidth: 400}} onChange={(event: any) => {
                    listStore.search(event.currentTarget.value)
                }} placeholder={i18n.t("common.search")}/>
                <Button icon={<PlusOutlined/>} type={"primary"} onClick={showDrawer}>{i18n.t("common.create")}</Button>
            </Flex>
            <Divider/>
            <ListComponent
                onSortReplace={false}
                store={listStore}
                renderItem={(item, index: number) => {
                    return (
                        <Row style={{width: "100%", cursor: "pointer"}} gutter={[8, 0]} onClick={() => {
                            setAttrId(item.id)
                        }}>
                            <Col md={6}>
                                <div style={{display: "flex"}} className={"fw-500"}>
                                    <Typography.Paragraph style={{paddingLeft: "0.5rem"}}>
                                        <Tag color="green" style={{marginRight: 20}}>{index + 1}</Tag> {item.id}
                                    </Typography.Paragraph>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={{display: "flex"}}>
                                    <Typography.Paragraph style={{paddingLeft: "0.5rem"}}>
                                        {item.label}
                                    </Typography.Paragraph>
                                </div>
                            </Col>
                            <Col md={10}>
                                <Typography.Paragraph>
                                    <Tag color="geekblue">{i18n.t("attribute-kind." + item.kind)}</Tag>
                                </Typography.Paragraph>
                            </Col>
                            <Col md={2} onClick={(event: any) => {
                                event.stopPropagation()
                            }}>
                                <Popconfirm
                                    title={item.label}
                                    description={i18n.t("common.confirm-delete")}
                                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}

                                    open={deleteID === item.id}
                                    onConfirm={() => {
                                        if(collectionId) deleteAttribute(collectionId, item.id)
                                    }}
                                    cancelText={i18n.t("common.cancel")}
                                    okText={i18n.t("common.delete")}
                                    okButtonProps={{loading: confirmLoading}}
                                    onCancel={() => {
                                        setDeleteId(undefined)
                                    }}
                                >
                                    <DeleteOutlined onClick={() => {
                                        setDeleteId(item.id)
                                    }}/>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )
                }}
            />
            <CreateAttribute open={open} onClose={onClose} appId={appId} collectionId={collectionId}/>
            {(attrId && appId && collectionId) &&
                <AttributeEdit appId={appId} collectionId={collectionId} onClose={onCloseView} key={"attr-view-" + attrId}
                               open={!!attrId} attrId={attrId} onEdit={onClose}/>
            }
        </>
    )
}

export {Attributes}
