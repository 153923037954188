import React from "react";
import {Layout, Flex, Divider, Collapse, Badge} from 'antd'
import {CloseOutlined} from "@ant-design/icons";
import {ContentComponent} from "./Types";
import {generatePath, useMatches, useNavigate} from "react-router-dom";
import {ROUTE} from "src/routing"
import i18n from "i18next";
import './style.scss'


const ContentMenu: React.FC<{ store: any, route: string, title: any }> = ({store, route, title}) => {
    const navigate = useNavigate()
    const matches = useMatches()

    const contentOnClick = (el: any) => {
        navigate(generatePath(route, {appId: store.appId, collectionId: el._id}))

    }
    const singleOnClick = (el: any) => {
        navigate(generatePath(ROUTE.SinglePage, {appId: store.appId, singleId: el.id}))
    }
    const onCreate = (data: any) => {
        store.refresh()
    }

    const items: any[] = [
        {
            key: 'collection',
            label: i18n.t("collection.label").toUpperCase(),
            children: <ContentComponent key={"ContentComponent"} onCreate={onCreate} data={store.data.collection}
                                        onClick={contentOnClick} isActive={(el: any) => (
                matches.some((y: any) => (y.pathname === generatePath(ROUTE.ContentList, {
                    appId: store.appId,
                    collectionId: el._id
                })))
            )}/>,
            extra: <Badge count={store.data.collection.length} showZero color={"blue"}/>
        },
        {
            key: 'single',
            label: i18n.t("single.label").toUpperCase(),
            children: <ContentComponent key={"SingleComponent"} onCreate={onCreate} data={store.data.single}
                                        onClick={singleOnClick} isActive={(el: any) => (
                matches.some((y: any) => (y.pathname === generatePath(ROUTE.SinglePage, {
                    appId: store.appId,
                    singleId: el.id
                })))
            )}/>,
            extra: <Badge count={store.data.single.length} showZero color={"blue"}/>
        },


    ];


    return (
        <>
            {store.open &&
                <Layout.Sider theme={"light"} collapsed={false} className={"c-builder-menu"}
                              width={250}
                >
                    <Flex justify={"space-between"}>
                        <h3>{title}</h3>
                        <CloseOutlined className={"c-builder-close"} onClick={store.onClose}/>
                    </Flex>
                    <Divider style={{margin: 0}}/>

                    <Collapse className={"c-builder-c"} defaultActiveKey={items.map((el: any) => (el.key))}
                              expandIconPosition={"end"}
                              ghost items={items}/>
                </Layout.Sider>
            }
        </>

    )
}

export {ContentMenu}