import {Button, Popconfirm, App} from "antd";
import i18n from "i18next";
import React from "react";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Request} from "kaiju.core";
import {Props} from "./BulkActions"


export const DeleteBulkAction: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const {notification} = App.useApp()

    const deleteItems = () => {
        setLoading(true)
        Request("CollectionGui.item.delete.many", {
            collection: props.collectionId,
            id: props.selectedState.id
        }).then(({result}) => {
            if (result) {
                notification.success({
                    message: i18n.t('common.deleted'),
                    placement: 'topRight',
                });
                props.successCallback()
            }
            setOpen(false)
            setLoading(false)
        })

    }
    return (
        <Popconfirm
            title={""}
            description={i18n.t("common.confirm-delete")}
            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
            open={open}
            onConfirm={() => {
                deleteItems()
            }}
            cancelText={i18n.t("common.cancel")}
            okText={i18n.t("common.delete")}
            cancelButtonProps={{loading: loading}}
            okButtonProps={{loading: loading}}
            onCancel={() => {
                setOpen(false)
            }}
        >
            <Button danger onClick={() => {
                setOpen(true)
            }} icon={<DeleteOutlined/>}>
                {i18n.t("common.delete")}
            </Button>
        </Popconfirm>
    )

}