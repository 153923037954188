import React from 'react'
import {Form, useDynamicForm} from "kaiju.core";
import {Button, App} from "antd";
import i18n from "i18next";

const Settings: React.FC<{ collectionId?: string }> = ({collectionId}) => {
    const {notification} = App.useApp()
    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        saveDiff: false,
        loadRequest: {
            method: "ContentTypeBuilder.collection.settings",
            params: {
                collection: collectionId
            }
        },
        loadExtraParams: {
            collection: collectionId
        },
        saveRequest: {
            method: "ContentTypeBuilder.collection.settings.update",
            params: {
                collection: collectionId
            }
        },
        successCallback: (props) => {
            notification.success({
                duration: 2,
                message: i18n.t("common.updated"),
            });
        }
    })
    return (
        <div style={{maxWidth: 500}}>
            <Form store={store.formStore}>
                <Button onClick={()=>{store.submit()}} type={"primary"}>{i18n.t("common.save")}</Button>
            </Form>
        </div>
    )

}

export {Settings}
