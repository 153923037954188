import React from 'react'
import i18n from "i18next";
import {Tag} from "antd";
import {Request} from 'kaiju.core';
import {prop} from "react-data-table-component/dist/DataTable/util";

interface Interface {
    notification: any,
    collectionId: any
    onCreate: any
}

const createMessage = (exists: string[]) => {
    return <div>
        {exists.map((id: string) => {
            return <div><Tag color="orange">{id}</Tag></div>
        })}
    </div>
}

const CreateItemStore = (props: Interface) => {
        return {
            fields: [{
                id: "item",
                fields: [
                    {
                        id: "id",
                        kind: "text",
                        required: true,
                        label: i18n.t("common.id"),
                        regex: {
                            pattern: "^[\\S\r\n]+$",
                            msg: "item.error.id-validation"
                        },
                    }
                ]
            }],
            disableGroups: true,
            onSubmit: (values: any, formikState: any) => {
                Request("CollectionGui.item.create.many", {
                    ...values,
                    collection: props.collectionId,
                }).then((response) => {
                    if (response.result) {
                        if (response.result.is_exists.length > 0) {
                            props.notification.warning({
                                message: i18n.t('item.error.is_exists'),
                                description: createMessage(response.result.is_exists),
                                placement: 'topRight',
                            });
                        }

                        if (response.result.created) {
                            props.notification.success({
                                message: i18n.t('item.success-created'),
                                placement: 'topRight',
                            });
                            props.onCreate()
                        } else {
                            props.notification.warning({
                                message: i18n.t('item.error.nothing-created'),
                                placement: 'topRight',
                            });
                        }
                        formikState.resetForm({})
                    }
                })
            }
        };
    }
;

export {CreateItemStore}


