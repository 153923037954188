import React from 'react'
import {App, Alert, Modal} from "antd";
import i18n from "i18next";
import {Form, Request} from "kaiju.core";
import {CreateItemStore} from './store'
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTE} from "src/routing";

const CreateSynthetic: React.FC<{ appId: string, open: boolean, collectionId: string, onClose: any }> = (params) => {
    const navigate = useNavigate()

    Request("CollectionGui.item.create.synthetic", {collection: params.collectionId}).then((resp: any) => {
        if (resp.result){
            navigate(generatePath(ROUTE.ContentItem, {appId: params.appId, collectionId: params.collectionId, itemId: resp.result}))
        }
    })
    return <Modal
        open={params.open}
        loading={true}
        title={i18n.t("item.create-process")}
    >
    </Modal>
}

const CreateExternal: React.FC<{ collectionId: string, open: boolean, onClose: any, onCreate: any }> = (params) => {
    const {notification} = App.useApp()

    // // get data from loader
    const formStore = Form.useFormStore(CreateItemStore({
        notification: notification,
        collectionId: params.collectionId,
        onCreate: params.onCreate
    }))
    return <>
        <Modal
            title={i18n.t("item.create")}
            open={params.open}
            onOk={formStore.submit}
            // confirmLoading={confirmLoading}
            onCancel={() => {
                formStore.reset({})
                params.onClose();
            }}
        >
            <Form store={formStore} key={"CreateItemForm"}/>
            <Alert
                description={i18n.t("item.id-info")}
                type="info"
                showIcon
            />
        </Modal>
    </>
}

export const CreateItem: React.FC<{
    appId: string,
    collectionId: string,
    collectionData: any,
    open: boolean,
    onClose: any,
    onCreate: any
}> = ({
          appId,
          collectionId,
          collectionData,
          open,
          onClose,
          onCreate
      }) => {


    return (
        <>
            {(open && !collectionData.is_synthetic_id) &&
                <CreateExternal collectionId={collectionId} open={open} onClose={onClose} onCreate={onCreate}/>
            }
            {(open && collectionData.is_synthetic_id) &&
                <CreateSynthetic appId={appId} open={open} onClose={onClose} collectionId={collectionId}/>
            }
        </>
    );
}
